import axios from "./_base";
import errorHandler from "./_errorHandler";

export const updateSettings = (vue, settings, callback, errorCallback) => {
    const formData = new FormData();
    let i = 1;
    vue._.forEach(settings, (value, key) => {
        if (key === 'logo_image' && vue._.isObject(value)) {
            formData.append(key, value);
        } else {
            formData.append(key, value);
        }

        if (i === vue._.size(settings)) {
            axios.post(`http://192.168.43.96/api/update_settings`, formData, {
                headers: {
                    'Authorization': `Bearer ${vue.$store.state.user.token}`,
                    'Content-Type': 'multipart/form-data',
                },
            }).then((response) => {
                callback(response.data);
            }).catch((error) => {
                errorHandler(vue, error, errorCallback)
            });
        }
        i += 1;
    });
};

export const getSettings = (vue, callback, errorCallback) => {
    axios.get(`http://192.168.43.96/api/get_settings`, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};
