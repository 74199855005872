<template>
  <div>
    <CForm @submit="submit" v-if="loaded">
      <CCard>
        <CCardBody>

          <CAlert color="success text-center" v-if="!_.isEmpty(sentMessage)" @update:show="sentMessage = ''">
            {{ sentMessage }}
          </CAlert>

          <div class="row">

            <div class="col-md-6 col-sm-12">
              <CInput
                  :label="$t('establishment_name')"
                  type="text"
                  v-model="settings.establishment_name"
                  @keydown="resetErrors('establishment_name')"
                  :invalid-feedback="_.has(errors, 'establishment_name') ? errors.establishment_name : ''"
                  :is-valid="_.has(errors, 'establishment_name') ? false : null"
                  ref="name"
              >
              </CInput>
            </div>

            <div class="col-md-6 col-sm-12">
              <CInput
                  :label="$t('email')"
                  type="text"
                  v-model="settings.email"
                  @keydown="resetErrors('email')"
                  :invalid-feedback="_.has(errors, 'email') ? errors.email : ''"
                  :is-valid="_.has(errors, 'email') ? false : null"
              >
              </CInput>
            </div>

            <div class="col-md-6 col-sm-12">
              <CInput
                  :label="$t('phone')"
                  type="text"
                  v-model="settings.phone"
                  @keydown="resetErrors('phone')"
                  :invalid-feedback="_.has(errors, 'phone') ? errors.phone : ''"
                  :is-valid="_.has(errors, 'phone') ? false : null"
                  class="input-ltr-direction"
              >
              </CInput>
            </div>

            <div class="col-md-6 col-sm-12">
              <CInput
                  :label="$t('commercial_register_number')"
                  type="text"
                  v-model="settings.commercial_register_number"
                  @keydown="resetErrors('commercial_register_number')"
                  :invalid-feedback="_.has(errors, 'commercial_register_number') ? errors.commercial_register_number : ''"
                  :is-valid="_.has(errors, 'commercial_register_number') ? false : null"
              >
              </CInput>
            </div>

            <div class="col-md-6 col-sm-12">
              <CInput
                  :label="$t('vat_registration_number')"
                  type="text"
                  v-model="settings.vat_registration_number"
                  @keydown="resetErrors('vat_registration_number')"
                  :invalid-feedback="_.has(errors, 'vat_registration_number') ? errors.vat_registration_number : ''"
                  :is-valid="_.has(errors, 'vat_registration_number') ? false : null"
              >
              </CInput>
            </div>

<!--            <div class="col-md-6 col-sm-12">-->
<!--              <CInput-->
<!--                  :label="$t('other_seller_id')"-->
<!--                  type="text"-->
<!--                  v-model="settings.other_seller_id"-->
<!--                  @keydown="resetErrors('other_seller_id')"-->
<!--                  :invalid-feedback="_.has(errors, 'other_seller_id') ? errors.other_seller_id : ''"-->
<!--                  :is-valid="_.has(errors, 'other_seller_id') ? false : null"-->
<!--              >-->
<!--              </CInput>-->
<!--            </div>-->

            <div class="col-md-6 col-sm-12">
              <CInput
                  :label="$t('vat_percentage')"
                  type="text"
                  v-model="settings.vat_percentage"
                  @keydown="resetErrors('vat_percentage')"
                  :invalid-feedback="_.has(errors, 'vat_percentage') ? errors.vat_percentage : ''"
                  :is-valid="_.has(errors, 'vat_percentage') ? false : null"
                  class="input-ltr-direction"
              >
              </CInput>
            </div>

          </div>

          <div class="row">
            <div class="col-md-12 col-sm-12 text-center" style="padding-bottom: 20px;">
              <upload-image @done="changeLogo"
                            :placeholder="$t('select_logo_image')"
                            :imageUrl="settings.logo_image_base64"
                            :imageUrlSize="settings.logo_size"
                            @removeImage="setRemoveLogo"
                            style="margin: 0 auto;"
                            :error="_.has(errors, 'logo_image') ? errors.logo_image : ''"
              />
            </div>
          </div>

          <div class="wy-settings-address-block">
            <div class="wy-settings-address-block-title">{{ $t('address_information') }}</div>
            <div class="row">

              <div class="col-md-6 col-sm-12">
                <CInput
                    :label="$t('building_number')"
                    type="text"
                    v-model="settings.building_number"
                    @keydown="resetErrors('building_number')"
                    :invalid-feedback="_.has(errors, 'building_number') ? errors.building_number : ''"
                    :is-valid="_.has(errors, 'building_number') ? false : null"
                >
                </CInput>
              </div>

              <div class="col-md-6 col-sm-12">
                <CInput
                    :label="$t('street')"
                    type="text"
                    v-model="settings.street"
                    @keydown="resetErrors('street')"
                    :invalid-feedback="_.has(errors, 'street') ? errors.street : ''"
                    :is-valid="_.has(errors, 'street') ? false : null"
                >
                </CInput>
              </div>

              <div class="col-md-6 col-sm-12">
                <CInput
                    :label="$t('district')"
                    type="text"
                    v-model="settings.district"
                    @keydown="resetErrors('district')"
                    :invalid-feedback="_.has(errors, 'district') ? errors.district : ''"
                    :is-valid="_.has(errors, 'district') ? false : null"
                >
                </CInput>
              </div>

              <div class="col-md-6 col-sm-12">
                <CInput
                    :label="$t('city')"
                    type="text"
                    v-model="settings.city"
                    @keydown="resetErrors('city')"
                    :invalid-feedback="_.has(errors, 'city') ? errors.city : ''"
                    :is-valid="_.has(errors, 'city') ? false : null"
                >
                </CInput>
              </div>

              <div class="col-md-6 col-sm-12">
                <CInput
                    :label="$t('zip_code')"
                    type="text"
                    v-model="settings.zip_code"
                    @keydown="resetErrors('zip_code')"
                    :invalid-feedback="_.has(errors, 'zip_code') ? errors.zip_code : ''"
                    :is-valid="_.has(errors, 'zip_code') ? false : null"
                >
                </CInput>
              </div>

              <div class="col-md-6 col-sm-12">
                <CInput
                    :label="$t('address_secondary_number')"
                    type="text"
                    v-model="settings.address_secondary_number"
                    @keydown="resetErrors('address_secondary_number')"
                    :invalid-feedback="_.has(errors, 'address_secondary_number') ? errors.address_secondary_number : ''"
                    :is-valid="_.has(errors, 'address_secondary_number') ? false : null"
                >
                </CInput>
              </div>

              <div class="col-md-6 col-sm-12">
                <CInput
                    :label="$t('short_address')"
                    type="text"
                    v-model="settings.short_address"
                    @keydown="resetErrors('short_address')"
                    :invalid-feedback="_.has(errors, 'short_address') ? errors.short_address : ''"
                    :is-valid="_.has(errors, 'short_address') ? false : null"
                >
                </CInput>
              </div>

              <div class="col-md-6 col-sm-12">
                <CInput
                    :label="$t('gps_location_url')"
                    type="text"
                    v-model="settings.gps_location_url"
                    @keydown="resetErrors('gps_location_url')"
                    :invalid-feedback="_.has(errors, 'gps_location_url') ? errors.gps_location_url : ''"
                    :is-valid="_.has(errors, 'gps_location_url') ? false : null"
                >
                  <template #append>
                    <a v-show="!_.isEmpty(settings.gps_location_url) && validURL(settings.gps_location_url)"
                       :href="settings.gps_location_url"
                       target="_blank"
                       class="btn btn-red text-white">
                      <span class="wy-icon-gps-location" style="margin: 0; font-size: 22px;"></span>
                    </a>
                  </template>
                </CInput>
              </div>

            </div>
          </div>


        </CCardBody>
        <CCardFooter>
          <div class="row">
            <div class="col">
              <CButton type="submit" size="sm" color="red" class="px-4">
                <CIcon name="cil-check-circle"/>
                {{ $t('save') }}
              </CButton>
            </div>
            <div class="col text-right">
            </div>
          </div>
        </CCardFooter>
      </CCard>
    </CForm>
  </div>
</template>

<script>
import {getSettings, updateSettings} from "../../api/settings";
import UploadImage from "../components/UploadImage.vue";

export default {
  name: 'Settings',
  components: {
    UploadImage
  },
  data() {
    return {
      errors: {},
      error: '',
      sentMessage: '',
      //
      id: '',
      settings: {},
      //
      loaded: false,
    };
  },
  mounted() {
    this.tryGetSettings();
  },
  methods: {
    resetErrors(property) {
      this.sentMessage = '';
      if (!this._.isUndefined(property)) {
        delete this.errors[property];
      } else {
        this.errors = {};
        this.error = ''
      }
    },
    tryGetSettings() {
      getSettings(this, (response) => {
        this.settings = response.settings;
        this.loaded = true;
      }, (error) => {
        this.error = error.message;
      });
    },
    englishNumbers(number) {
      const ar = '٠١٢٣٤٥٦٧٨٩'.split('');
      const en = '0123456789'.split('');
      return number.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (x) => en[ar.indexOf(x)]);
    },
    validate(callback) {
      let hasErrors = false;
      this.resetErrors();

      this.settings.commercial_register_number = this.englishNumbers(this.settings.commercial_register_number);
      this.settings.vat_registration_number = this.englishNumbers(this.settings.vat_registration_number);


      this.$forceUpdate();
      if (!hasErrors) {
        callback()
      }
    },
    submit(event) {
      if (this._.isFunction(event.preventDefault)) {
        event.preventDefault();
      }
      this.sentMessage = '';
      this.validate(() => {
        updateSettings(this, this.settings, (response) => {
          this.sentMessage = response.message;
          this.$router.push({name: 'Settings'});
        }, (error) => {
          if (this._.has(error, 'errors')) {
            let i = 1;
            this._.forEach(error.errors, (item, key) => {
              this.errors[key] = item[0];
              if (i === this._.size(error.errors)) {
                this.$forceUpdate();
              }
              i += 1;
            });
          }
        });
      });
    },
    validURL(url) {
      const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))|'+ // OR ip (v4) address
          'localhost'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      return !!pattern.test(url);
    },
    changeLogo(file) {
      this.settings.logo_image = file;
    },
    setRemoveLogo(status) {
      this.settings.remove_logo_image = status;
    },
  },
}
</script>

<style lang="scss">
.wy-settings-address-block {
  background-color: #EEE;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 10px;

  .wy-settings-address-block-title {
    background-color: #FFFFFF;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    padding: 5px 15px;
    display: inline-block;
    margin: 0 0 10px 0;
  }
}
.padding-left-0 {
  [dir=ltr] & {
    padding-left: 0;
  }
  [dir=rtl] & {
    padding-right: 0;
  }
}
.padding-right-1 {
  [dir=ltr] & {
    padding-right: 5px;
  }
  [dir=rtl] & {
    padding-left: 5px;
  }
}
</style>
