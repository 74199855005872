<template>
  <div class="wy-upload-container">
    <input type="file"
           accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml, image/bmp"
           @change="changeImage"
           :ref="uploadImageInputRef" style="display: none;">
    <div class="wy-upload-box" :class="{ 'wy-upload-image-has-error': !_.isEmpty(errorString) }">
      <div class="wy-upload-delete" v-if="imageSource" @click="deleteImage">
        <img
            src="~@/assets/images/delete_white.svg"
        />
      </div>
      <div class="wy-upload-undo-remove-image" v-if="removeImage" @click="undoRemoveImage">
        <img
            src="~@/assets/images/undo_white.svg"
        />
      </div>
      <div class="wy-upload-alter"
           style="display: flex; align-items: center; justify-content: center;"
           @click="$refs[uploadImageInputRef].click();">
        <div>
          <div v-if="!imageSource">{{ placeholder }}</div>
          <img :src="imageSource" alt="" class="" v-if="imageSource" style="max-width: 240px; max-height: 240px;">
        </div>
      </div>
      <div class="wy-upload-size" v-if="imageSize">{{ imageSize }}</div>
    </div>
    <div v-if="!_.isEmpty(errorString)" class="text-danger"><small>{{ errorString }}</small></div>
  </div>
</template>

<script>
export default {
  name: 'UploadImage',
  props: {
    action: Function,
    placeholder: {
      type: String,
      default: '',
    },
    enabled: {
      type: Boolean,
      default: false,
    },
    imageUrl: {
      type: String,
      default: '',
    },
    imageUrlSize: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      uploadImageInputRef: `upload-image-input-${this._uid}`,
      image: null,
      removeImage: false,
      errorString: '',
    }
  },
  computed: {
    imageName() {
      if (this.image !== null) {
        return this.image.name;
      }
      return '';
    },
    imageSource() {
      if (this.image !== null) {
        return URL.createObjectURL(this.image);
      } else if (this.imageUrl !== '' && !this.removeImage) {
        return this.imageUrl;
      }
      return false;
    },
    imageSize() {
      if (this.image !== null) {
        return this.size(this.image.size);
      } else if (this.imageUrlSize !== '' && !this.removeImage) {
        return this.size(this.imageUrlSize);
      }
      return '';
    },
  },
  watch: {
    error(error) {
      this.errorString = error;
    },
    removeImage(status) {
      this.$emit('removeImage', status);
    },
    imageUrl() {
      this.removeImage = false;
    }
  },
  methods: {
    changeImage(event) {
      this.errorString = '';
      this.removeImage = false;
      if (!this._.isEmpty(event.target.files) && this._.isObject(event.target.files[0])) {
        this.image = event.target.files[0];
        this.$emit('done', this.image);
        this.$forceUpdate();
      }
    },
    undoRemoveImage() {
      this.removeImage = false;
    },
    deleteImage() {
      this.errorString = '';
      this.$refs[this.uploadImageInputRef].value = '';
      this.image = null;
      if (this.imageUrl !== '') {
        this.removeImage = true;
      }
    },
    /**
     * Format bytes as human-readable text.
     *
     * @param bytes Number of bytes.
     * @param si True to use metric (SI) units, aka powers of 1000. False to use
     *           binary (IEC), aka powers of 1024.
     * @param dp Number of decimal places to display.
     *
     * @return Formatted string.
     */
    size(bytes, si = true, dp = 1) {
      const thresh = si ? 1000 : 1024;

      if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
      }

      const units = si
          ? [this.$t('KB'), this.$t('MB'), this.$t('GB'), this.$t('TB'), this.$t('PB'), this.$t('EB'), this.$t('ZB'), this.$t('YB')]
          : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
      let u = -1;
      const r = 10**dp;

      do {
        bytes /= thresh;
        ++u;
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


      return bytes.toFixed(dp) + ' ' + units[u];
    }
  },
};
</script>

<style lang="scss" scoped>
.wy-upload-container {
  position: relative;
  width: 258px;
  .wy-upload-box {
    position: relative;
  }
  .wy-upload-delete {
    position: absolute;
    background-color: rgba(22, 22, 22, .6);
    cursor: pointer;
    transition: all 350ms ease;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 5px;
    top: 5px;
    [dir=ltr] & {
      right: 5px;
    }
    [dir=rtl] & {
      left: 5px;
    }
    &:hover {
      background-color: #3462A5;
    }
    img {
      width: 16px;
      display: block;
    }
  }
  .wy-upload-undo-remove-image {
    position: absolute;
    background-color: rgba(22, 22, 22, .6);
    cursor: pointer;
    transition: all 350ms ease;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 5px;
    top: 5px;
    [dir=ltr] & {
      left: 5px;
    }
    [dir=rtl] & {
      right: 5px;
    }
    &:hover {
      background-color: #3462A5;
    }
    img {
      width: 16px;
      display: block;
    }
  }
  .wy-upload-alter {
    width: 256px;
    height: 256px;
    background-color: #EEEEEE;
    color: #222222;
    cursor: pointer;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    transition: all 350ms ease;
    &:hover, &:active {
      background-color: #DDDDDD;
      color: #3462A5;
    }
  }
  .wy-upload-size {
    color: rgba(22, 22, 22, 0.95);
    background-color: rgba(255, 255, 255, 0.8);
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    position: absolute;
    padding: 2px 4px;
    bottom: 14px;
    [dir=ltr] & {
      right: 14px;
    }
    [dir=rtl] & {
      left: 14px;
    }
  }
  .wy-upload-image-has-error {
    border: #C41117 1px solid;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }
}
</style>
